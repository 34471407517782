<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <adw-button color="primary" @click="submitForm()"> Создать </adw-button>
    </div>
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name" placeholder="Введите название"></el-input>
      </el-form-item>
      <el-form-item label="Описание" prop="description">
        <el-input
          v-model="form.description"
          placeholder="Введите описание"
        ></el-input>
      </el-form-item>
      <el-form-item label="Бренды участники" prop="brandIDs">
        <el-select
          v-model="form.brandIDs"
          multiple
          filterable
          default-first-option
          clearable
          placeholder="Выберите бренды"
          :class="$style.select"
        >
          <el-option
            v-for="item in brands"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Дата начала" prop="startsAt">
        <AdwCalendar
          id="orderDatePicker"
          :value="form.startsAt"
          :disabledDates="disabledDays"
          minimum-view="day"
          maximum-view="day"
          placeholder="Выберите дату"
          is-hide-icon
          @selected="form.startsAt = $event"
        />
      </el-form-item>
      <el-form-item label="Дата окончания" prop="endsAt">
        <AdwCalendar
          id="orderDatePicker"
          :value="form.endsAt"
          :disabledDates="disabledDays"
          minimum-view="day"
          maximum-view="day"
          placeholder="Выберите дату"
          is-hide-icon
          @selected="form.endsAt = $event"
        />
      </el-form-item>
      <div
        v-for="(level, index) of form.levels"
        :key="index"
        :class="$style.inputsWrapper"
      >
        <div :class="$style.inputs">
          <el-form-item
            :label="level.label"
            :prop="'levels.' + index + '.achieveAmount'"
            :rules="rules.levels.achieveAmount"
          >
            <el-input
              v-model="form.levels[index].achieveAmount"
              type="number"
              placeholder="Введите сумму до следующего уровня"
              min="0"
              :readonly="index === 0"
              :class="$style.input"
            ></el-input>
          </el-form-item>
          <el-form-item
            :prop="'levels.' + index + '.cashbackSize'"
            :rules="rules.levels.cashBackSize"
          >
            <el-input
              v-model="form.levels[index].cashbackSize"
              type="number"
              placeholder="Введите кешбэк в %"
              min="0"
              :readonly="index === 0"
              :class="$style.input"
            ></el-input
          ></el-form-item>
        </div>
        <template v-if="index === 0">
          <div :class="$style.prizes">
            <span :class="$style.prizesText"
              >Введите призы для трех первых мест в стартовом уровне</span
            >
            <div
              v-for="(prize, index) of form.levels[0]?.prizes"
              :key="index"
              :class="$style.prize"
            >
              <el-form-item
                :label="`Для ${prize.place} места`"
                :prop="'levels.' + 0 + '.prizes.' + index + '.prize'"
                :rules="rules.prizes.prize"
              >
                <el-input
                  v-model="form.levels[0].prizes[index].prize"
                  type="number"
                  placeholder="Введите размер приза"
                  min="0"
                  :class="$style.input"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </template>
      </div>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import { BONUS_PROGRAM_LEVELS } from '@/constants/bonusProgram'

export default {
  BONUS_PROGRAM_LEVELS,
  components: {},
  ADDSELLERS_ADMIN_ROUTES,
  mixins: [notifications],
  data() {
    return {
      form: {
        name: '',
        description: '',
        brandIDs: [],
        startsAt: null,
        endsAt: null,
        levels: [
          {
            achieveAmount: 0,
            cashbackSize: 0,
            label: BONUS_PROGRAM_LEVELS.FIRST_LEVEL,
            prizes: [
              { prize: null, place: 1 },
              { prize: null, place: 2 },
              { prize: null, place: 3 }
            ]
          },
          {
            achieveAmount: null,
            cashbackSize: null,
            label: BONUS_PROGRAM_LEVELS.SECOND_LEVEL
          },
          {
            achieveAmount: null,
            cashbackSize: null,
            label: BONUS_PROGRAM_LEVELS.THIRD_LEVEL
          },
          {
            achieveAmount: null,
            cashbackSize: null,
            label: BONUS_PROGRAM_LEVELS.FOURTH_LEVEL
          },
          {
            achieveAmount: null,
            cashbackSize: null,
            label: BONUS_PROGRAM_LEVELS.FIFTH_LEVEL
          },
          {
            achieveAmount: null,
            cashbackSize: null,
            label: BONUS_PROGRAM_LEVELS.SIXTH_LEVEL
          }
        ]
      },
      limit: 100000,
      brands: [],
      day: 24 * 60 * 60 * 1000,
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        description: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        brandIDs: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, выберите хоть один бренд',
            trigger: 'change'
          }
        ],
        startsAt: [
          {
            required: true,
            message: 'Пожалуйста, выберите дату начала',
            trigger: 'blur'
          }
        ],
        endsAt: [
          {
            required: true,
            trigger: 'blur',
            message: 'Пожалуйста, выберите дату начала'
          },
          {
            trigger: 'blur',
            validator: this.validateEndDate
          }
        ],
        levels: {
          type: 'array',
          required: true,
          achieveAmount: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur'
            },
            {
              trigger: 'blur',
              validator: this.validateAchieveAmount
            }
          ],
          cashBackSize: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur'
            }
          ]
        },
        prizes: {
          type: 'array',
          required: true,
          prize: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur'
            }
          ]
        }
      }
    }
  },
  computed: {
    disabledDays() {
      return { to: new Date(Date.now() - this.day) }
    }
  },
  async created() {
    await this.getBrandsList()
  },
  methods: {
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          this.form.levels[0].prizes = this.form?.levels[0]?.prizes.map(
            (item) => ({
              place: item.place ?? '',
              prize: Number(item.prize) ?? 0
            })
          )

          this.form.levels = this.form?.levels?.map((item) => ({
            achieveAmount: Number(item.achieveAmount) ?? 0,
            cashbackSize: Number(item.cashbackSize) ?? 0,
            prizes: item.prizes
          }))

          const { error } =
            await delivery.AddwineCore.BonusProgramActions.create({
              ...this.form
            })

          loading.close()

          if (error) return

          this.showNotification('Бонусная программа успешно создана', 'success')

          this.$router.push(ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST)
        }
      })
    },
    async getBrandsList() {
      const { value, error } = await delivery.AddwineCore.BrandsActions.getList(
        {
          limit: this.limit
        }
      )

      if (error) return

      this.brands = value.data
    },
    validateEndDate(_, value, callback) {
      if (this.form?.startsAt > this.form?.endsAt) {
        callback(
          new Error('Дата окончания не может быть раньше, чем дата начала')
        )
      } else {
        callback()
      }
    },
    validateAchieveAmount(rule, value, callback) {
      let hasError = false

      for (let i = 0; i <= this.form?.levels?.length - 1; i++) {
        if (!i || !this.form?.levels[i]?.achieveAmount) {
          continue
        }
        if (
          Number(this.form.levels[i - 1].achieveAmount) >=
            Number(this.form.levels[i].achieveAmount) &&
          rule.field === 'levels.' + i + '.achieveAmount'
        ) {
          hasError = true
          callback(
            new Error(
              'Сумма следующего уровня не может быть меньше или равна сумме предыдущей'
            )
          )

          continue
        }
      }

      if (!hasError) {
        callback()
      }
    }
  }
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    margin-bottom: 1rem;
  }
  .form {
    & > div > label {
      text-align: left;
    }

    .inputsWrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      .prizes {
        display: flex;
        flex-direction: column;

        .prizesText {
          @include H200;
          margin-bottom: 1rem;
        }

        .prize {
          display: flex;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }

          & > div > label {
            text-align: left;
          }

          .input {
            display: flex;
            width: 20rem;
          }
        }
      }

      .inputs {
        display: flex;
        & > div:last-child > div {
          margin-left: 3rem !important;
        }

        & > div > label {
          text-align: left;
        }

        .input {
          display: flex;
          width: 20rem;
        }
      }
    }

    .select {
      width: 100%;
    }
  }
}
</style>
